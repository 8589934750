import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Card, Col, Row, Accordion} from "react-bootstrap";


export const query = graphql`
query {
  empfang: file(relativePath: {eq: "empfang.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1200, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
     sprechzimmer: file(relativePath: {eq: "sprechzimmer.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1200, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}


`
export default class ServicesPage extends React.Component {
    constructor(props) {
        super(props)

    }


    render() {
        console.log(this.props)
        return (
            <Layout>
                <SEO title="Räume"/>

                <section className="page-section" id="services">
                    <div className="container">
                        <h1 className="text-center mt-0">Unsere Räumlichkeiten</h1>
                        <hr className="divider my-4"/>
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h4>Empfang</h4>
                                <Img fluid={this.props.data.empfang.childImageSharp.fluid} />
                            </div>
                            <div className="col-lg-6 text-center">
                                <h4>Sprechzimmer</h4>
                                <Img fluid={this.props.data.sprechzimmer.childImageSharp.fluid} />


                            </div>
                        </div>

                    </div>
                </section>


            </Layout>
        )
    }
}

